<template>
  <div class="verify" >
    <div class="title">
      <router-link :to="{name:'Home'}">首页</router-link>
      <a>&emsp;<span class="Breadcrumb"><i class="el-icon-arrow-right "></i></span>&emsp;客服验证</a>
    </div>
    <div v-loading="loading" :class="{'background1':isSafe,'background2':isDanger,'background3':isEmpty,'body':true}">
      <div class="query_input">
        <input v-model.trim="num" type="text" placeholder="输入客服以及收款账户进行验证">
        <span @click="custVerify(num)">重新查询</span>
      </div>
      <div class="info">
        <div class="alipay">
          <div class="alipay_item" v-for="(item,index) in alipay" :key="`alipay${index}`">
            <div class="alipay_account">
              <span class="alipay_title">账户：</span>
              <span class="alipay_text">{{ item.account }}</span>
            </div>
            <div class="alipay_name">
              <span class="alipay_title">收款人：</span>
              <span class="alipay_text">{{ item.name }}</span>
            </div>
          </div>
        </div>
        <div class="wechat">
          <div class="wechat_item" v-for="(item,index) in wechat" :key="`wechat${index}`">
            <div class="wechat_account">
              <span class="wechat_title">账户：</span>
              <span class="wechat_text">{{ item.account }}</span>
            </div>
            <div class="wechat_name">
              <span class="wechat_title">收款人：</span>
              <span class="wechat_text">{{ item.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {apiCusVerify} from '@/request/API';
export default {
  name: '',
  props: {
  },
  components: {

  },
  data () {
    return {
      num:this.$route.params.num,
      isSafe:false,
      isDanger:false,
      isEmpty:false,
      loading:false,
      alipay:[
        // {account:'13842555498',name:'常晶'},
        // {account:'1330287777@qq.com',name:'金华市博淳网络科技有限公司 '},
        // {account:'pxb71688@163.com',name:'金华市博淼网络科技有限公司'},
      ],
      wechat:[
        // {account:'pxb7688',name:'常晶'},
        // {account:'bishinan',name:'让时间来诠释'},
      ],
    }
  },
  methods: {
    queryNum(num){
      switch (num){
        case '1':
          this.isSafe=true
          this.isDanger=false
          this.isEmpty=false
          break;
        case '2':
          this.isSafe=false
          this.isDanger=true
          this.isEmpty=false
          break;
        case '3':
          this.isSafe=false
          this.isDanger=false
          this.isEmpty=true
          break;
        default:
          this.isSafe=false
          this.isDanger=false
          this.isEmpty=true
          break;
      }
    },
    /**
     * 重新查询按钮
     * @param number
     */
    custVerify(number){
      this.loading = true
      apiCusVerify({
        number:number
      }).then(res => {
        this.queryNum('1')
        this.loading = false
      }).catch(err=>{
        switch (err.code) {
          case 101:
            this.queryNum('2')
            break;
          case 0:
            this.queryNum('3')
            break;
        }
        this.loading = false
      })
    }
  },
  mounted () {
    this.custVerify(this.num)
  },
  watch: {

  },
  computed: {
  }
}
</script>

<style lang='less' scoped>
.verify{
  width: 1200px;
  .title{
    height: 80px;
    display: flex;
    align-items: center;
    margin-left: 5px;
    a{
      font-size: 12px;
    }
    a:first-of-type{
      font-size: 16px;
      font-family: PingFangSC-Thin, PingFang SC;
      font-weight: 300;
      color: #8F8F8F;
    }
    a:last-of-type{
      font-size: 16px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 400;
      color: #434ED6;
      cursor: pointer;
    }
  }
  .body{
    width: 1200px;
    height: 870px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .query_input{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 311px;
      position: relative;
      input{
        width: 246px;
        height: 50px;
        background: #FFFFFF;
        border-radius: 30px;
        border: none;
        padding: 0 130px 0 24px;
      }
      input::-webkit-input-placeholder {
        font-size: 16px;
        font-family: PingFangSC-Thin, PingFang SC;
        font-weight: 300;
        color: #BFBFBF;
      }
      span{
        position: absolute;
        left: 70%;
        width: 120px;
        height: 50px;
        line-height: 50px;
        background: linear-gradient(180deg, #FFDB51 0%, #FF8435 100%);
        border-radius: 30px;
        font-size: 20px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        cursor: pointer;
        text-align: center;
      }
    }
    .info{
      margin: 220px 0 65px 0;
      width: 1200px;
      background: #FFFFFF;
      display: flex;
      flex-direction: row;
      .alipay{
        width: 50%;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #5B5B5B;
        line-height: 25px;
        .alipay_item{
            padding: 0 0 20px 220px;
            span{
              font-size: 18px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #5B5B5B;
              line-height: 25px;
            }
          .alipay_title{
            display: inline-block;
            min-width: 72px;
            margin: 0 10px 0 0;
          }
        }
      }
      .wechat{
        width: 50%;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #5B5B5B;
        line-height: 25px;
        .wechat_item{
          padding: 0 0 20px 185px;
          span{
            font-size: 18px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #5B5B5B;
            line-height: 25px;
          }
          .wechat_title{
            display: inline-block;
            min-width: 72px;
            margin: 0 10px 0 0;
          }
        }
      }
    }
  }
  .background1{
    background: url("../../../assets/image/Verify/cusSer1.png") no-repeat;
  }
  .background2{
    background: url("../../../assets/image/Verify/cusSer2.png") no-repeat;
  }
  .background3{
    background: url("../../../assets/image/Verify/cusSer3.png") no-repeat;
  }
}
</style>
